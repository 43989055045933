import { NavigationBar } from "components";
import * as React from "react";

interface IHeaderProps {
  isHomePage?: boolean;
}

const Header: React.FunctionComponent<IHeaderProps> = (
  { isHomePage }
) => {

  return (
    <header>
      <NavigationBar isHomePage={isHomePage} />
    </header>
  )
};

export default Header;
